import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

import SimpleGallery from "../components/modules/FSGridGallery"


export default () => (
  <Layout>
      <SEO title="Portfolio of work" keywords="Gallery, Examples, Work, Portfolio" description="Browse a gallery of our work and treatments." />
      <section className="container section-spacer dark md:w-9/12 lg:w-8/12">
    <h1>Gallery</h1>
    <p className="lead">
      Below are just some of the examples of our recent installations in homes and commercial properties.
    </p>
    <p>For more examples of our work you can follow us on <a href="https://www.instagram.com/barroncarpetsandfloors/" rel="noopener noreferrer">Instagram</a> or <a href="https://www.facebook.com/barroncarpetsandfloors" target="_blank" rel="noopener noreferrer">Facebook</a>.</p>

    <SimpleGallery />
    </section>
  </Layout>
)
